/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const NorjanVaellus = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vaellukset/pohjois-norjan-vaellus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Norjan Vaellus',
    description:
      'Haluatko kokea todella ainutlaatuisen vaelluksen? Loistavaa, sillä Norjan vaellus tulee sen sinulle tarjoamaan! Tämä retki suuntautuu Reisan kansallispuistoon, jossa vaellamme suurten vuorten ympäröimänä Reisajokea pitkin. Matkalla koetaan useita vaikuttavia vesiputouksia, ihaillaan kivikauden kivitaidetta ja nautitaan alueen rikkaasta kasvistosta ja eläimistöstä. Eikä tässä vielä kaikki!',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="pohjois-norjan-vaellus"
    >
      <div className="camp-text-content">
        <h2>Pohjois-Norjan vaellus</h2>
        <h3>31.7.-4.8.2024</h3>
        <p className="bolded">
          Haluatko kokea todella ainutlaatuisen vaelluksen? Loistavaa, sillä
          Norjan vaellus tulee sen sinulle tarjoamaan! Tämä retki suuntautuu
          Reisan kansallispuistoon, jossa vaellamme suurten vuorten ympäröimänä
          Reisajokea pitkin. Matkalla koetaan useita vaikuttavia vesiputouksia,
          ihaillaan kivikauden kivitaidetta ja nautitaan alueen rikkaasta
          kasvistosta ja eläimistöstä. Eikä tässä vielä kaikki!
        </p>
        <br />
        <p>
          Reisajoki on leikkaantunut vuoristotasankoon, kuin kirveen terä
          muodostaen pitkän Reisan laakson. Norjan vaellus alkaa noin kahden
          tunnin jokivenekyydillä kohti Reisajoen yläjuoksua. Jo pelkästään
          jokivenekyyti on ikimuistoinen kokemus läpi syvän ja upean kanjonin.
          Luonto täällä on täysin ainutlaatuinen. Reisan kansallispuistossa on
          havaittu yhteensä 193 Skandinavian 230 vuoristokasvista, mikä tekee
          alueesta kasvitieteellisesti täysin uniikin. Vaelluksen reitillä on
          useita vaikuttavia vesiputouksia, mukaan lukien kuuluisa Mollisfossen.
          Pohjois-Euroopan korkein vesiputous, Mollisfossen on 269 metriä
          korkea, jonka vapaa pudotus on 140 metriä.
        </p>
        <br />
        <p>
          Vaellusreitti vaihtelee jatkuvasti, eikä yksikään kilometri ole
          samanlainen. Matkalla on mm. erittäin kivikkoista polkua, sademetsän
          tuntua, hiekkakangasmaastoa, pienten jokien ylityksiä, isoja ja pieniä
          siltoja ja joen Reisajoen ylitys soutuveneellä, itse tietenkin
          soutaen!
        </p>
      </div>
      <GatsbyImage
        image={data.campImg1.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Vaelluksen aikataulu ja vaativuus:</p>
        <p>
          Saapuminen Ovi Raishiin 31.7., jossa ensimmäinen telttayöpyminen
          tapahtuu. Seuraavana aamuna jokivene vie meidät upealle, noin kahden
          tunnin pituiselle matkalle vaelluksen alkukohtaan. Itse vaellus kestää
          neljä päivää ja kolme yötä, jotka nukutaan teltoissa. Vaelluksen
          pituus on 32-45 km, tämä riippuu veden korkeudesta ja kuinka pitkälle
          jokivene meidät pystyy kyyditsemään. Vaellus päättyy takaisin Ovi
          Raishiin sunnuntaina 4.8.2024.
        </p>
        <br />
        <p>
          Tämä vaellus sopii hyväkuntoisille naisille, joilla on jo pitkän
          vaelluksen kokemusta. Vaellukselle otetaan enintään seitsemän naista,
          minimäärä vaelluksen toteutumiseen on neljä. Tämä retki ei ole sovi
          ensimmäiseksi pitkäksi vaellukseksi.
        </p>
        <br />
        <p className="bolded">Vaelluksen opas</p>
        <p>
          Oppaana vaelluksella toimii eräopas Sanni Lampinen. Muoniossa asuva
          Sanni on toiselta ammatiltaan fysioterapeutti ja hän toimii oppaana
          myös Discover Laplandin hiihtovaelluksilla. Sanni on kiinnostunut
          ihmisen kokonaisvaltaisesta hyvinvoinnista ja siitä, miten voimme
          luonnossa eheyttää itseämme. Retkeilijänä Sanni on nautiskelija ja
          seikkailija. Hän nauttii ympäröivästä luonnosta, sen kauniista
          yksityiskohdista, rauhasta, eri sääolosuhteista ja kaikesta
          erilaisuudesta mitä luonto meille tarjoaa.
        </p>
        <br />
        <p className="bolded">Ennen retkeä</p>
        <p>
          Lähetämme sinulle tarkan pakkauslistan, jonka käymme läpi retkelle
          lähtijöiden kanssa. Annamme tietoa ja vinkkejä juuri tähän
          vaellukseen, ruokailuun ja vaatetukseen, jotta sinulla on turvallinen
          sekä luotettava olo lähteä vaellukselle. Retkellä on myös oma
          whats-up-ryhmä, jossa osallistujat voivat kysellä mm. kimppakyytejä /
          telttojen jakoa.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg4.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg5.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Mitä tarvitset mukaasi vaellukselle?</p>
        <p>
          - Vaellukseen sekä eri säihin sopivat vaatteet ja kengät <br />
          - Teltta, makuualustat sekä makuupussi <br />
          - Rinkka <br />
          - Ruuantekovälineet, ruokaa, vesipullo / termospullo <br />-
          Kävely/vaellussauvat
        </p>
        <br />
        <p className="bolded">Vaelluksen hinta on 750€ (sis alv.)</p>
        <br />
        <p>
          Hintaan kuuluu: <br />
          Pakkauslista, päiväkohtainen retkisuunnitelma ja
          turvallisuussuunnitelma <br />
          Opas koko retken ajaksi <br />
          Jokivenekyyti <br />
          Ennen retkeä: Osallistujan kanssa retkelle valmistautuminen
          verkkotapaamisissa (ensimmäinen verkkotapaaminen on 3.6.2024 klo 19-21
          ja viimeinen 24.7.2024 klo 19-21), joissa käymme läpi tarvittavat
          vaellustarvikkeet, vaatetus, ruoka sekä sen laitto sekä tarvittava apu
          Ovi Raishiin saapumiseen.
        </p>
        <br />
        <p>
          <span className="bolded">
            Ilmoittaudu mukaan sähköpostitse{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>{' '}
            ja toimi näin:
          </span>
          <br />
          OTSIKKO: Norjan vaellus <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi vaelluskokemuksesi? <br />
          Mikä on kuntotasosi eli kuinka paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?{' '}
          <br />
          <br />
          Sinulle tulee itsellesi kustannettavaksi omat kuljetukset Norjaan,
          mutta toivomme kimppakyytejä kaikille lähtijöille. Tästä voidaan sopia
          yhdessä lähtijöiden kanssa.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg2.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p>
          <span className="bolded">Retken varaus- ja peruutusehdot:</span>{' '}
          <br />
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 250€. Kun lasku on maksettu, on paikka
          vaellukselle varattu ja saat paluupostina vaelluksen materiaalit;
          pakkauslistan ja retkisuunnitelman sekä onlinetapaamisten linkit.{' '}
          <br />
          <br />
          Vaelluksen loppulaskun 500€ saat sähköpostiisi vaelluksen
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen anettelatvapiikkila@gmail.com.{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . <br />
          <br />
          Jos perut osallistumisesi vaellukselle missä kohtaa tahansa, pidätämme
          toimitetut vaelluksen materiaalien kulut sekä toimistokulut 250€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . <br />
          <br />
          Lue lisää varaus - ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          . <br />
          <br />
          HUOM! Pidäthän huolen, että sinulla on voimassa oleva
          vapaa-ajanvakuutus vaelluksen aikana.
          <br />
          <br />
          Tätä vaellusta on harva tehnyt ja kokenut, lähde mukaamme nauttimaan
          tästä upeudesta! Mikäli sinulla on kysyttävää vaelluksesta, voit olla
          yhteydessä Anetteen joko meilitse{' '}
          <a href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(relativePath: { eq: "images/hikes/norway-hike-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(relativePath: { eq: "images/hikes/norway-hike-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(relativePath: { eq: "images/hikes/norway-hike-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(relativePath: { eq: "images/hikes/norway-hike-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(relativePath: { eq: "images/hikes/norway-hike-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg5: file(relativePath: { eq: "images/hikes/norway-hike-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg6: file(relativePath: { eq: "images/hikes/norway-hike-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(relativePath: { eq: "images/hikes/norway-hike-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default NorjanVaellus;
